const eProjectCode = {
  CONTAINER_LOADER: 'clp',
  AXA_SMART_DRIVE: 'axa',
  WAYFINDER: 'wf',
  SQM_CHATBOT: 'sqm',
  PRICE_FETCHER: 'pf',
}

// TODO: Change LongDescriptions, Reviewers
const arrProjects = [
  {
    eProjectCode: eProjectCode.CONTAINER_LOADER,
    strName: 'Container Loader Program',
    strShortDescription: 'A simulation software to calculate the approximate optimal configuration to load cargos into containers to achieve maximal space usage. Try our container loader here',
    arrLongDescription: [
      'A simulation software to calculate the approximate optimal configuration to load cargos into containers to achieve maximal space usage.',
      'Alternatively, user can also provide multiple destinations and container selections and the software will calculate the combination of containers recommended to be used.',
      'Built using our custom C++ framework for performance and Unity3D for 3D visualization.',
    ],
    strImgPathLogo: 'logo-clp.png',
    arrImgPathsPhoto: ['clp-00.png', 'clp-01.jpg', 'clp-02.jpg', 'clp-03.jpg', 'clp-04.jpg', 'clp-05.jpg'],
    strProjectDuration: '8 Months',
    strProjectMembers: '2 Unity3D & C++ Programmers',
    arrTechstacks: ['Unity3D', 'KeDAEngine (C++)'],
    arrTechImgs: ['logo-unity.png', 'logo-cpp.png'],
    bDemo: true,
    objReviewer: {
      strName: 'PT. Citra Kreasi Makmur',
      strTitle: '',
      strQuote: '"Software yang dibuat sangat membantu kami memaksimalkan pengiriman barang dengan cepat."',
      strImgPath: 'icon-avatar.png',
    },
  },
  {
    eProjectCode: eProjectCode.AXA_SMART_DRIVE,
    strName: 'AXA Smart Drive',
    strShortDescription: 'An interactive networked VR experience where the driver and passenger can enjoy a cruise around the virtual city of Anfield',
    arrLongDescription: ['An interactive networked VR experience where the driver and passenger can enjoy a cruise around the virtual city of Anfield'],
    strImgPathLogo: 'logo-axa.png',
    arrImgPathsPhoto: ['axa-00.png', 'axa-01.jpg', 'axa-02.jpg', 'axa-03.jpg', 'axa-04.jpg', 'axa-05.jpg', 'axa-06.jpg'],
    strProjectDuration: '2 Weeks / 14 Days',
    strProjectMembers: '3 People',
    arrTechstacks: ['Unreal Engine 4', 'HTC Vive'],
    arrTechImgs: ['logo-unreal.png', 'logo-htc-vive.png'],
    bDemo: false,
    objReviewer: {
      strName: 'PT. Asuransi AXA Indonesia',
      strTitle: '',
      strQuote: '"KeDA Tech membantu kami menciptakan program untuk memenugi kebutuhan client kami dengan menciptakan simulasi drive melalui VR Experience."',
      strImgPath: 'icon-avatar.png',
    },
  },
  {
    eProjectCode: eProjectCode.WAYFINDER,
    strName: 'WayFinder',
    strShortDescription: 'Indoor navigation integrated with custom Warehouse Management System to allow workers to insert and take items with improved  efficiency.',
    arrLongDescription: ['Indoor navigation integrated with custom Warehouse Management System to allow workers to insert and take items with improved  efficiency.'],
    strImgPathLogo: 'logo-wayfinder.png',
    arrImgPathsPhoto: ['wayfinder-00.png', 'wayfinder-01.png', 'wayfinder-02.png'],
    strProjectDuration: '3 Months',
    strProjectMembers: '2 Front-end / 1 Back-end / 1 C++ Engine',
    arrTechstacks: ['Polymer', 'TypeScript', 'KeDAEngine (C++)'],
    arrTechImgs: ['logo-polymer.png', 'logo-ts.png', 'logo-cpp.png'],
    bDemo: false,
    objReviewer: {
      strName: 'PT. Citra Kreasi Makmur',
      strTitle: '',
      strQuote: '"Wayfinder salah satu apps terbaik untuk gudang kami yang cukup besar."',
      strImgPath: 'icon-avatar.png',
    },
  },
  {
    eProjectCode: eProjectCode.SQM_CHATBOT,
    strName: 'Real Time Chatbot',
    strShortDescription: 'A software application used to carry out online chat conversations via text instead of providing direct customer contact with the admin.',
    arrLongDescription: ['A software application used to carry out online chat conversations via text instead of providing direct customer contact with the admin.'],
    strImgPathLogo: 'logo-sqm.jpg',
    arrImgPathsPhoto: ['sqm-00.jpg', 'sqm-01.png', 'sqm-02.png', 'sqm-03.png', 'sqm-04.png'],
    strProjectDuration: '2 Months',
    strProjectMembers: '2 Back-end / 2 Front-end',
    arrTechstacks: ['Django (python)', 'ReactJS', 'MySQL', 'MongoDB'],
    arrTechImgs: ['logo-django.png', 'logo-react.png', 'logo-mysql.png', 'logo-mongodb.png'],
    bDemo: false,
    // objReviewer: {
    //   strName: 'SQM Property',
    //   strTitle: '',
    //   strQuote: '"Hasil memuaskan dengan fitur dan desain yang simpel, memudahkan customer kami untuk bisa terhubung secara cepat dengan SQM Property."',
    //   strImgPath: 'icon-avatar.png',
    // },
  },
  {
    eProjectCode: eProjectCode.PRICE_FETCHER,
    strName: 'Price Fetcher',
    strShortDescription: 'Custom-built software that can query and compare prices of any goods sold in online e-commerce such as Lazada, Tokopedia, and Amazon.',
    arrLongDescription: [
      'Custom-built software that can query and compare prices of any goods sold in online e-commerce such as Lazada, Tokopedia, and Amazon.',
      'Built using our custom C++ backend framework for maximum performance and Qt for our easy-to-use UI.',
    ],
    strImgPathLogo: 'logo-price-fetcher.png',
    arrImgPathsPhoto: ['pf-00.png', 'pf-01.jpg'],
    strProjectDuration: '2 Months',
    strProjectMembers: '2 UI & C++ Programmers',
    arrTechstacks: ['KeDAEngine (C++)', 'Qt'],
    arrTechImgs: ['logo-cpp.png', 'logo-qt.png'],
    bDemo: false,
    objReviewer: {
      strName: 'Kreasi2Shop',
      strTitle: '',
      strQuote: '"Price fetcher sangat membantu kami untuk melakukan penjualan di toko online."',
      strImgPath: 'icon-avatar.png',
    },
  },
]

const arrPartners = [
  {
    strName: 'Shinta VR',
    strPath: 'logo-shinta-vr.png',
  },
  {
    strName: 'Kreasi2Shop',
    strPath: 'logo-k2s.png',
  },
  {
    strName: 'Altech Omega',
    strPath: 'logo-altech-omega.png',
  },
  {
    strName: 'Citra Kreasi Makmur',
    strPath: 'logo-ckm.png',
  },
  {
    strName: 'Selaras Citra Nusantara Perkasa',
    strPath: 'logo-scnp.png',
  },
  {
    strName: 'AXA',
    strPath: 'logo-axa.png',
  },
]

export default {
  name: 'Projects',

  data: () => ({
    eProjectCode,
    g_arrProjects: arrProjects,
    g_arrPartners: arrPartners,
  }),
}
