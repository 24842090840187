<template>
  <base-section
    id="k-d-a-project-demo"
    class="secondary"
    space="36"
  >
    <v-container>
      <k-d-a-demo-c-l-p
        v-if="checkProject(eProjectCode.CONTAINER_LOADER)"
      />

      <base-body
        v-else
        text-color="black"
      >
        {{ m_strProjectNotFound }}
      </base-body>
    </v-container>
  </base-section>
</template>

<script>
  import Projects from '@/mixins/projects'

  export default {
    name: 'KDAProjectDemo',

    components: {
      KDADemoCLP: () => import('@/components/KDADemoCLP'),
    },

    mixins: [Projects],

    data: () => ({
      m_strProjectNotFound: 'Project Not Found',
    }),

    methods: {
      checkProject (eCode) {
        return this.$route.params.strProjectCode.toLowerCase() === eCode.toLowerCase()
      },
    },
  }
</script>
